import React, {useState} from "react";
import {Dialog, DialogContent, Grid, IconButton, Typography, useMediaQuery, useTheme} from "@mui/material";
import PieGraph from "./graph/PieGraph";
import RadarGraph from "./graph/RadarGraph";
import {StreamGraph} from "./graph/StreamGraph";
import {TimelineGraph} from "./graph/TimelineGraph";
import ChordGraph2 from "./graph/ChordGraph2";
import {Lens, ZoomIn} from "@mui/icons-material";
import SwarmPlotGraph from "./graph/SwarmPlotGraph";
import SwarmPlotGraphOld from "./graph/SwarmPlotGraphOld";

const mapGrafici = [
    {
        id: 'parole',
        title: "Grafico parole",
        description: "Le parole chiave che sono state più utilizzate durante tutte le sessioni."
    },
    {
        id: 'radar',
        title: "Radar Sentiment",
        description: "Il Sentiment delle parole chiave più usate.\n" +
            "Rappresenta il sentimento ('Negativo', 'Neutro', 'Positivo') delle parole chiave. " +
            " È calcolato da un'intelligenza artificiale " +
            " in base al contesto della frase in cui la keyword è stata detta. Il suo valore è compreso fra 0 e 2.",
    },
    {
        id: 'sessioni',
        title: "Attività delle sessioni",
        description: "Grafico temporale che rappresenta la quantità di idee " +
            "e frasi prodotte dai partecipanti delle tavole rotonde durante l'arco delle sessioni."
    },
    {
        id: 'sessioni-timeline',
        title: "Dettaglio attività sessioni",
        description: "Grafico temporale che rappresenta di mezz'ora in mezz'ora la quantità di idee " +
            "e frasi prodotte dai partecipanti delle tavole rotonde durante l'arco delle sessioni."
    },
    {
        id: 'chord',
        title: "Grafico condivisione",
        description: "Tutte le connessioni che si sono create fra i vari tavoli durante l'arco delle sessioni."
    },
    {
        id: 'emptyChord',
        title: "Grafico condivisione",
        description: "Se nessuno avesse condiviso le proprie idee."
    },
    {
        id: 'swamPlot',
        title: "Swarmplot dei sentiment delle vostre idee",
        description: "Ogni cerchietto corrisponde ad un'idea processata da un tavolo." +
            " La dimensione è legata al Sentiment: più è grande più il Sentiment è positivo." +
            " Il colore invece è legato al tavolo che ha prodotto l'idea"
    },
]

export const Graphs = ({sessionsLabels, event, sessions, words, activity, connectionsByWords}) => {
    //console.log("sessionsLabels:",sessionsLabels)
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

    const [open, setOpen] = useState(false)
    const [graphId, setGraphId] = useState('')
    const [viewTimeLineDetails, setViewTimeLineDetails] = useState(false)
    //const [switchChord, setSwitchChord] = useState(false)

    const handleSetOpen = (id) => {
        setOpen(!open)
        setGraphId(id)
    }

    function handleZoom() {
        setViewTimeLineDetails(!viewTimeLineDetails)
    }

    /*function handleChord() {
        setSwitchChord(!switchChord)
    }*/

    return (
        <Grid container spacing={{xs: 8, md: 10}} pb={10} justifyContent={'center'}>
            <Dialog open={open} onClose={() => handleSetOpen('')}>
                <DialogContent>
                    <Typography gutterBottom variant={'h4'}>
                        {mapGrafici.find(g => g.id === graphId) ? mapGrafici.find(g => g.id === graphId).title : ''}:
                    </Typography>
                    <Typography variant={'subtitle2'} fontWeight={'lighter'}>
                        {mapGrafici.find(g => g.id === graphId) ? mapGrafici.find(g => g.id === graphId).description : ''}
                    </Typography>
                </DialogContent>
            </Dialog>
            {event.graphPie && <Grid item xs={12} md={6} sx={{textAlign: 'center', height: 'max(320px, 40vw)'}}>
                <PieGraph handleSetOpen={handleSetOpen} words={words}/>
            </Grid>}
            {event.graphRadar && <Grid item xs={12} md={6} sx={{textAlign: 'center', height: 'max(320px, 40vw)'}}>
                <RadarGraph handleSetOpen={handleSetOpen} words={words} sessions={sessions}/>
            </Grid>}
            {event.graphTimeLine &&
                <>
                    {viewTimeLineDetails ?
                        <Grid item xs={12} md={12} sx={{textAlign: 'center', height: 'max(320px, 30vw)'}}>
                            <TimelineGraph activity={activity} handleSetOpen={handleSetOpen} handleZoom={handleZoom}/>
                        </Grid>
                        :
                        <Grid item xs={12} md={12} sx={{textAlign: 'center', height: 'max(320px, 30vw)'}}>
                            <StreamGraph activity={activity} handleSetOpen={handleSetOpen} handleZoom={handleZoom}/>
                        </Grid>}
                    </>
            }
            {/*<Grid item xs={12} md={6} mt={2} sx={{textAlign: 'center', height: 'max(420px, 45vw)'}}>
                <ChordGraph2 empty connectionsByWords={connectionsByWords} handleSetOpen={handleSetOpen}/>
            </Grid>*/}
            {event.graphChord && <Grid item xs={12} md={12} sx={{textAlign: 'center', height: 'max(420px, 45vw)'}}>
                <ChordGraph2 connectionsByWords={connectionsByWords} handleSetOpen={handleSetOpen}/>
            </Grid>}
            {/*event.graphSwarmPlot && !mobile && <Grid mx={4} item xs={12} md={12} sx={{textAlign: 'center', height: 'max(420px, 45vw)'}}>
                <SwarmPlotGraph sessions={sessions} connectionsByWords={connectionsByWords} handleSetOpen={handleSetOpen}/>
            </Grid>*/}
            {event.graphSwarmPlot && !mobile && <Grid mx={4} item xs={12} md={12} sx={{textAlign: 'center', height: 'max(420px, 45vw)'}}>
                <SwarmPlotGraphOld sessions={sessions} sessionsLabels={sessionsLabels} connectionsByWords={connectionsByWords} handleSetOpen={handleSetOpen}/>
            </Grid>}
        </Grid>
    )
}