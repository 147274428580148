import React from "react";
import {Container} from "@mui/material";

export const Default = ({children}) => {
    return (
        <Container>
            {children}
        </Container>
    )
}
