import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import {PleshTheme} from "../theme/pleshTheme";

export const Description = ({descriptions}) => {
    return (
        <Stack spacing={2} sx={{mb:4}}>
            {descriptions.map(t =>
                <Box key={t.title}>
                    <Typography variant={'h6'} color={PleshTheme.palette.primary.main}>
                        {t.title}
                    </Typography>
                    <Typography variant={'body2'} fontWeight={300}>
                        {t.body}
                    </Typography>
                </Box>
            )}
        </Stack>
    )
}
