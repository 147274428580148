import {Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Textfit} from "react-textfit";
import CountUp from "react-countup";
import {PleshTheme} from "../theme/pleshTheme";
import {each} from "lodash";

const Counters = ({event, sessions}) => {
    let numIdee = event.numIdee || (() => {
        let _numIdee = 0
        each(sessions, (session) => {
            _numIdee += session.length
        })

        return _numIdee > 0 ? _numIdee : null
    })()

    const numParticipants = event.num_participants
    const ore = event.total_hours
    const numtavoli = event.total_tables

    const numText = `${numIdee}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    const firstPartNumText = numText.slice(0, numText.length - 1)

    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

    const [color, setColor] = useState(PleshTheme.palette.primary.main)

    /*const [idee, setIdee] = useState(numIdee - 10);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (idee < numIdee) {
                setIdee(idee => idee + 1);
            }
            else {
                clearTimeout(timeout)
                //setColor({...color, idee: PleshTheme.palette.primary.main})
            }
        }, 170)

        return () => {
            console.log("cleared")
            clearTimeout(timeout);
        }
    }, [idee])*/

    const [showText, setShowText] = useState(false);
    const [blink, setBlink] = useState(false);

    let timeOutBlink
    function myFunction() {
        let min = 0.5,
            max = 1;
        let rand = Math.random() * (max - min + 1) + min;
        setShowText((showText) => !showText);
        timeOutBlink = setTimeout(myFunction, Math.random() * Math.random() * 90);
    }

    useEffect(() => {
        let interval
        if(blink) {
            myFunction()
            setTimeout(function () {
                setBlink(false)
                clearInterval(interval);
                clearInterval(timeOutBlink);
            }, 800)
            /*const time = Math.random() * Math.random() * 12
            console.log("time:",time)
            interval = setInterval(() => {
                console.log("ASD ASDA SDS AD:", time)
                setShowText((showText) => !showText);
            }, time);

                setTimeout(function () {
                    console.log("setTimeout")
                    setBlink(false)
                    clearInterval(interval);
                }, 2000);*/
        }
        return () => clearInterval(interval);
    }, [blink]);

    useEffect(() => {
    }, [showText])

    return (<Grid my={4} container spacing={{xs: 4, md: 4,}} justifyContent={'space-between'}>
            {numIdee && <Grid mt={2} item xs={12} md={12}>
                <Typography gutterBottom variant={'h6'}>Idee totali</Typography>
                <Textfit mode="multi"
                         style={{
                             marginInline: 'auto',
                             height: mobile ? "85px" : "min(40vw, 120px)",
                             textTransform: 'uppercase',
                             fontFamily: "'Grotesk', sans-serif",
                             fontWeight: '700',
                             lineHeight: '1',
                             color: blink ? showText ? color : 'transparent' : color,
                         }} min={10} max={200}>
                    <CountUp
                        start={0}
                        end={numIdee}
                        duration={4}
                        useEasing
                        separator="."
                        decimals={0}
                        decimal=","
                        prefix=""
                        suffix=""
                        onEnd={() => {
                            //setColor({...color, idee: PleshTheme.palette.primary.main})
                            //setBlink(true)
                        }}
                    >
                        {/*({countUpRef, start}) => (
                            <div>
                                <span ref={countUpRef}/>
                                <button onClick={start}>Start</button>
                            </div>
                        )*/}
                    </CountUp>
                </Textfit>
            </Grid>}
            {ore && <Grid mt={2} item xs={12} md={4}>
                <Typography gutterBottom>Ore di discussione</Typography>
                <Textfit mode="multi"
                         style={{
                             height: mobile ? "55px" : "min(20vw, 55px)",
                             textTransform: 'uppercase',
                             fontFamily: "'Grotesk', sans-serif",
                             fontWeight: '700',
                             lineHeight: '1',
                             color: blink ? showText ? color : 'transparent' : color,
                         }} min={1} max={200}>
                    <CountUp
                        start={0}
                        end={ore}
                        duration={3}
                        useEasing
                        scrollSpyDelay={100}
                        enableScrollSpy
                        scrollSpyOnce
                        separator="."
                        decimals={0}
                        decimal=","
                        prefix=""
                        suffix=""
                    >
                    </CountUp>
                </Textfit>
            </Grid>}
            {numParticipants && <Grid mt={2} item xs={12} md={4}>
                <Typography gutterBottom>Partecipanti</Typography>
                <Textfit mode="multi"
                         style={{
                             height: mobile ? "55px" : "min(20vw, 55px)",
                             textTransform: 'uppercase',
                             fontFamily: "'Grotesk', sans-serif",
                             fontWeight: '700',
                             lineHeight: '1',
                             color: blink ? showText ? color : 'transparent' : color,
                         }} min={1} max={200}>
                    <CountUp
                        start={0}
                        end={numParticipants}
                        duration={3}
                        useEasing
                        scrollSpyDelay={100}
                        enableScrollSpy
                        scrollSpyOnce
                        separator="."
                        decimals={0}
                        decimal=","
                        prefix=""
                        suffix=""
                    >
                    </CountUp>
                </Textfit>
            </Grid>}
            {numtavoli && <Grid mt={2} item xs={12} md={4}>
                <Typography gutterBottom>Numero di tavoli</Typography>
                <Textfit mode="multi"
                         style={{
                             height: mobile ? "55px" : "min(20vw, 55px)",
                             textTransform: 'uppercase',
                             fontFamily: "'Grotesk', sans-serif",
                             fontWeight: '700',
                             lineHeight: '1',
                             color: blink ? showText ? color : 'transparent' : color,
                         }} min={1} max={200}>
                    <CountUp
                        start={0}
                        end={numtavoli}
                        duration={3.5}
                        useEasing
                        scrollSpyDelay={100}
                        enableScrollSpy
                        scrollSpyOnce
                        separator="."
                        decimals={0}
                        decimal=","
                        prefix=""
                        suffix=""
                    >
                    </CountUp>
                </Textfit>
            </Grid>}
        </Grid>
    );
}

export default Counters