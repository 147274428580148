import React, {useContext, useMemo, useState} from "react";
import {ResponsiveSwarmPlot} from '@nivo/swarmplot'
import _, {map} from 'lodash'
import {Autocomplete, Box, Chip, IconButton, Paper, Stack, TextField, Typography} from "@mui/material";
import {InfoOutlined, Square} from "@mui/icons-material";
import {PleshTheme} from "../../theme/pleshTheme";

const SwarmPlotGraph = ({sessions, connectionsByWords, handleSetOpen}) => {
    const [selectedSession, setSelectedSession] = useState('1')

    const _tavoli = useMemo(() => {
        if(!connectionsByWords)
            return null

        return Object.keys(connectionsByWords);
    }, [connectionsByWords])

    // x: sentiment, y: tavoli, grandezza: sentiment
    /**
     * {
     *     "id": "0.0",
     *     "group": "group A",
     *     "price": 1,
     *     "volume": 9
     *   }
     * */

    const [__data, sessionsKeys] = useMemo(() => {
        if(!sessions)
            return [[], []]

        return [_.map(sessions[selectedSession], (frase, index) => ({
            id: `${index} - ${frase.keyword}`,
            group: frase.table,
            wordCounter: _.split(frase.sentence, ' ').length,
            sentiment: frase.sentiment.score,
            frase: frase.sentence,
            citta: frase.zone,
            keyWord: frase.keyword
        })), Object.keys(sessions)]
    }, [sessions, selectedSession])

    return (<>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}
               sx={{width: '100%', textAlign: 'left'}}>
            <Typography>I Sentiment delle vostre idee</Typography>
            <IconButton onClick={() => handleSetOpen('swamPlot')}>
                <InfoOutlined/>
            </IconButton>
            <Autocomplete
                sx={{width: '50%', marginLeft: 'auto'}}
                value={selectedSession}
                getOptionLabel={(option) => `Sessione #${option}`}
                renderOption={(props, option) => (
                    <Box component="li" {...props} display={'flex'} justifyContent={'space-between'}>
                        <Chip label={`Sessione #${option}`} size={'small'} sx={{marginLeft: 1}}/>
                    </Box>
                )}
                onChange={(event, newValue) => {
                    setSelectedSession(newValue)
                }}
                renderInput={(params) => <TextField {...params} label="Sessione" />}
                options={sessionsKeys}
            />
        </Stack>
        <ResponsiveSwarmPlot
            data={__data}
            groups={_tavoli || ['1']}
            identity="id"
            value="sentiment"
            //tooltip={props => console.log("props:",props)}
            tooltip={(props) => <CustomTooltipSwarmPlot {...props}/>}
            //valueFormat=" >-$.2f"
            valueScale={{ type: 'symlog', min: 0, max: 3, reverse: false }}
            layout={"horizontal"}
            size={{
                key: 'sentiment',
                values: [
                    0,
                    2
                ],
                sizes: [
                    4,
                    30
                ]
            }}
            forceStrength={0.7}
            simulationIterations={300}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.6
                    ],
                    [
                        'opacity',
                        0.5
                    ]
                ]
            }}
            margin={{ top: 110, right: 25, bottom: 80, left: 55 }}
            axisTop={false/*{
                orient: 'top',
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -46
            }*/}
            axisRight={false/*{
                orient: 'right',
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 76
            }*/}
            axisBottom={{
                orient: 'bottom',
                tickSize: 15,
                tickPadding: 8,
                tickRotation: 90,
                legend: 'Sentiment (compreso fra 0 e 3)',
                legendPosition: 'end',
                legendOffset: 55
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 15,
                tickPadding: 8,
                tickRotation: 0,
                legend: 'TAVOLI',
                legendPosition: 'end',
                legendOffset: -50
            }}
        />
    </>)
}

export default SwarmPlotGraph

export const CustomTooltipSwarmPlot = (props) => {
    const {sentiment, frase, keyWord} = props.data
    const {color, group} = props
    return (
        <Stack direction={'column'} spacing={1} sx={{background: 'black'}} p={1} borderRadius={'4px'}
               alignItems={'left'} justifyContent={'center'} maxWidth={'160px'} flexWrap={'wrap'}>
            <Chip size={'small'} sx={{background: color}} label={`Tavolo ${group}:`}/>
            <Typography textAlign={'left'} fontSize={12}>{frase}</Typography>
            <Typography textAlign={'left'} fontSize={12} color={PleshTheme.palette.primary.main}>
                SENTIMENT: {sentiment?.toPrecision(2)}
            </Typography>
        </Stack>
    )
}

const data = [
    {
        "id": "0.0",
        "group": "group A",
        "price": 1,
        "volume": 9
    },
    {
        "id": "0.1",
        "group": "group A",
        "price": 471,
        "volume": 15
    },
    {
        "id": "0.2",
        "group": "group A",
        "price": 23,
        "volume": 11
    },
    {
        "id": "0.3",
        "group": "group A",
        "price": 444,
        "volume": 19
    },
    {
        "id": "0.4",
        "group": "group A",
        "price": 402,
        "volume": 14
    },
    {
        "id": "0.5",
        "group": "group A",
        "price": 81,
        "volume": 17
    },
    {
        "id": "0.6",
        "group": "group A",
        "price": 99,
        "volume": 13
    },
    {
        "id": "0.7",
        "group": "group A",
        "price": 404,
        "volume": 10
    },
    {
        "id": "0.8",
        "group": "group A",
        "price": 309,
        "volume": 20
    },
    {
        "id": "0.9",
        "group": "group A",
        "price": 86,
        "volume": 4
    },
    {
        "id": "0.10",
        "group": "group A",
        "price": 364,
        "volume": 16
    },
    {
        "id": "0.11",
        "group": "group A",
        "price": 166,
        "volume": 5
    },
    {
        "id": "0.12",
        "group": "group A",
        "price": 16,
        "volume": 19
    },
    {
        "id": "0.13",
        "group": "group A",
        "price": 392,
        "volume": 18
    },
    {
        "id": "0.14",
        "group": "group A",
        "price": 412,
        "volume": 6
    },
    {
        "id": "0.15",
        "group": "group A",
        "price": 372,
        "volume": 18
    },
    {
        "id": "0.16",
        "group": "group A",
        "price": 20,
        "volume": 8
    },
    {
        "id": "0.17",
        "group": "group A",
        "price": 305,
        "volume": 5
    },
    {
        "id": "0.18",
        "group": "group A",
        "price": 320,
        "volume": 5
    },
    {
        "id": "0.19",
        "group": "group A",
        "price": 463,
        "volume": 8
    },
    {
        "id": "0.20",
        "group": "group A",
        "price": 378,
        "volume": 9
    },
    {
        "id": "0.21",
        "group": "group A",
        "price": 55,
        "volume": 4
    },
    {
        "id": "0.22",
        "group": "group A",
        "price": 474,
        "volume": 8
    },
    {
        "id": "0.23",
        "group": "group A",
        "price": 334,
        "volume": 5
    },
    {
        "id": "0.24",
        "group": "group A",
        "price": 274,
        "volume": 16
    },
    {
        "id": "0.25",
        "group": "group A",
        "price": 38,
        "volume": 20
    },
    {
        "id": "0.26",
        "group": "group A",
        "price": 6,
        "volume": 9
    },
    {
        "id": "0.27",
        "group": "group A",
        "price": 38,
        "volume": 14
    },
    {
        "id": "0.28",
        "group": "group A",
        "price": 461,
        "volume": 7
    },
    {
        "id": "0.29",
        "group": "group A",
        "price": 469,
        "volume": 11
    },
    {
        "id": "0.30",
        "group": "group A",
        "price": 67,
        "volume": 11
    },
    {
        "id": "0.31",
        "group": "group A",
        "price": 298,
        "volume": 9
    },
    {
        "id": "0.32",
        "group": "group A",
        "price": 44,
        "volume": 11
    },
    {
        "id": "0.33",
        "group": "group A",
        "price": 311,
        "volume": 5
    },
    {
        "id": "0.34",
        "group": "group A",
        "price": 301,
        "volume": 11
    },
    {
        "id": "0.35",
        "group": "group A",
        "price": 482,
        "volume": 16
    },
    {
        "id": "0.36",
        "group": "group A",
        "price": 14,
        "volume": 6
    },
    {
        "id": "0.37",
        "group": "group A",
        "price": 85,
        "volume": 15
    },
    {
        "id": "0.38",
        "group": "group A",
        "price": 480,
        "volume": 16
    },
    {
        "id": "0.39",
        "group": "group A",
        "price": 31,
        "volume": 13
    },
    {
        "id": "0.40",
        "group": "group A",
        "price": 228,
        "volume": 5
    },
    {
        "id": "0.41",
        "group": "group A",
        "price": 181,
        "volume": 7
    },
    {
        "id": "0.42",
        "group": "group A",
        "price": 203,
        "volume": 14
    },
    {
        "id": "0.43",
        "group": "group A",
        "price": 350,
        "volume": 12
    },
    {
        "id": "0.44",
        "group": "group A",
        "price": 428,
        "volume": 15
    },
    {
        "id": "0.45",
        "group": "group A",
        "price": 255,
        "volume": 19
    },
    {
        "id": "0.46",
        "group": "group A",
        "price": 471,
        "volume": 6
    },
    {
        "id": "0.47",
        "group": "group A",
        "price": 154,
        "volume": 7
    },
    {
        "id": "0.48",
        "group": "group A",
        "price": 32,
        "volume": 12
    },
    {
        "id": "0.49",
        "group": "group A",
        "price": 274,
        "volume": 19
    },
    {
        "id": "0.50",
        "group": "group A",
        "price": 432,
        "volume": 10
    },
    {
        "id": "0.51",
        "group": "group A",
        "price": 252,
        "volume": 6
    },
    {
        "id": "0.52",
        "group": "group A",
        "price": 154,
        "volume": 16
    },
    {
        "id": "0.53",
        "group": "group A",
        "price": 265,
        "volume": 9
    },
    {
        "id": "0.54",
        "group": "group A",
        "price": 478,
        "volume": 7
    },
    {
        "id": "0.55",
        "group": "group A",
        "price": 225,
        "volume": 9
    },
    {
        "id": "0.56",
        "group": "group A",
        "price": 193,
        "volume": 7
    },
    {
        "id": "0.57",
        "group": "group A",
        "price": 403,
        "volume": 9
    },
    {
        "id": "0.58",
        "group": "group A",
        "price": 398,
        "volume": 13
    },
    {
        "id": "0.59",
        "group": "group A",
        "price": 13,
        "volume": 14
    },
    {
        "id": "0.60",
        "group": "group A",
        "price": 312,
        "volume": 13
    },
    {
        "id": "0.61",
        "group": "group A",
        "price": 492,
        "volume": 18
    },
    {
        "id": "0.62",
        "group": "group A",
        "price": 63,
        "volume": 12
    },
    {
        "id": "0.63",
        "group": "group A",
        "price": 355,
        "volume": 11
    },
    {
        "id": "1.0",
        "group": "group B",
        "price": 486,
        "volume": 10
    },
    {
        "id": "1.1",
        "group": "group B",
        "price": 75,
        "volume": 16
    },
    {
        "id": "1.2",
        "group": "group B",
        "price": 233,
        "volume": 5
    },
    {
        "id": "1.3",
        "group": "group B",
        "price": 69,
        "volume": 17
    },
    {
        "id": "1.4",
        "group": "group B",
        "price": 481,
        "volume": 15
    },
    {
        "id": "1.5",
        "group": "group B",
        "price": 121,
        "volume": 13
    },
    {
        "id": "1.6",
        "group": "group B",
        "price": 499,
        "volume": 5
    },
    {
        "id": "1.7",
        "group": "group B",
        "price": 363,
        "volume": 8
    },
    {
        "id": "1.8",
        "group": "group B",
        "price": 416,
        "volume": 6
    },
    {
        "id": "1.9",
        "group": "group B",
        "price": 136,
        "volume": 6
    },
    {
        "id": "1.10",
        "group": "group B",
        "price": 302,
        "volume": 10
    },
    {
        "id": "1.11",
        "group": "group B",
        "price": 2,
        "volume": 7
    },
    {
        "id": "1.12",
        "group": "group B",
        "price": 257,
        "volume": 20
    },
    {
        "id": "1.13",
        "group": "group B",
        "price": 0,
        "volume": 15
    },
    {
        "id": "1.14",
        "group": "group B",
        "price": 461,
        "volume": 20
    },
    {
        "id": "1.15",
        "group": "group B",
        "price": 52,
        "volume": 12
    },
    {
        "id": "1.16",
        "group": "group B",
        "price": 23,
        "volume": 17
    },
    {
        "id": "1.17",
        "group": "group B",
        "price": 221,
        "volume": 6
    },
    {
        "id": "1.18",
        "group": "group B",
        "price": 236,
        "volume": 8
    },
    {
        "id": "1.19",
        "group": "group B",
        "price": 234,
        "volume": 9
    },
    {
        "id": "1.20",
        "group": "group B",
        "price": 174,
        "volume": 5
    },
    {
        "id": "1.21",
        "group": "group B",
        "price": 167,
        "volume": 12
    },
    {
        "id": "1.22",
        "group": "group B",
        "price": 206,
        "volume": 4
    },
    {
        "id": "1.23",
        "group": "group B",
        "price": 287,
        "volume": 5
    },
    {
        "id": "1.24",
        "group": "group B",
        "price": 130,
        "volume": 11
    },
    {
        "id": "1.25",
        "group": "group B",
        "price": 12,
        "volume": 13
    },
    {
        "id": "1.26",
        "group": "group B",
        "price": 278,
        "volume": 14
    },
    {
        "id": "1.27",
        "group": "group B",
        "price": 51,
        "volume": 6
    },
    {
        "id": "1.28",
        "group": "group B",
        "price": 406,
        "volume": 18
    },
    {
        "id": "1.29",
        "group": "group B",
        "price": 396,
        "volume": 19
    },
    {
        "id": "1.30",
        "group": "group B",
        "price": 412,
        "volume": 20
    },
    {
        "id": "1.31",
        "group": "group B",
        "price": 84,
        "volume": 12
    },
    {
        "id": "1.32",
        "group": "group B",
        "price": 346,
        "volume": 9
    },
    {
        "id": "1.33",
        "group": "group B",
        "price": 213,
        "volume": 17
    },
    {
        "id": "1.34",
        "group": "group B",
        "price": 414,
        "volume": 4
    },
    {
        "id": "1.35",
        "group": "group B",
        "price": 198,
        "volume": 12
    },
    {
        "id": "1.36",
        "group": "group B",
        "price": 142,
        "volume": 4
    },
    {
        "id": "1.37",
        "group": "group B",
        "price": 86,
        "volume": 18
    },
    {
        "id": "1.38",
        "group": "group B",
        "price": 263,
        "volume": 11
    },
    {
        "id": "1.39",
        "group": "group B",
        "price": 184,
        "volume": 6
    },
    {
        "id": "1.40",
        "group": "group B",
        "price": 425,
        "volume": 13
    },
    {
        "id": "1.41",
        "group": "group B",
        "price": 347,
        "volume": 19
    },
    {
        "id": "1.42",
        "group": "group B",
        "price": 74,
        "volume": 13
    },
    {
        "id": "1.43",
        "group": "group B",
        "price": 2,
        "volume": 11
    },
    {
        "id": "1.44",
        "group": "group B",
        "price": 38,
        "volume": 17
    },
    {
        "id": "1.45",
        "group": "group B",
        "price": 171,
        "volume": 20
    },
    {
        "id": "1.46",
        "group": "group B",
        "price": 19,
        "volume": 4
    },
    {
        "id": "1.47",
        "group": "group B",
        "price": 119,
        "volume": 15
    },
    {
        "id": "1.48",
        "group": "group B",
        "price": 405,
        "volume": 9
    },
    {
        "id": "1.49",
        "group": "group B",
        "price": 348,
        "volume": 20
    },
    {
        "id": "1.50",
        "group": "group B",
        "price": 228,
        "volume": 10
    },
    {
        "id": "1.51",
        "group": "group B",
        "price": 399,
        "volume": 17
    },
    {
        "id": "1.52",
        "group": "group B",
        "price": 439,
        "volume": 14
    },
    {
        "id": "1.53",
        "group": "group B",
        "price": 187,
        "volume": 13
    },
    {
        "id": "1.54",
        "group": "group B",
        "price": 401,
        "volume": 16
    },
    {
        "id": "1.55",
        "group": "group B",
        "price": 31,
        "volume": 10
    },
    {
        "id": "1.56",
        "group": "group B",
        "price": 381,
        "volume": 17
    },
    {
        "id": "1.57",
        "group": "group B",
        "price": 410,
        "volume": 7
    },
    {
        "id": "1.58",
        "group": "group B",
        "price": 37,
        "volume": 16
    },
    {
        "id": "1.59",
        "group": "group B",
        "price": 496,
        "volume": 7
    },
    {
        "id": "1.60",
        "group": "group B",
        "price": 137,
        "volume": 4
    },
    {
        "id": "1.61",
        "group": "group B",
        "price": 113,
        "volume": 18
    },
    {
        "id": "1.62",
        "group": "group B",
        "price": 255,
        "volume": 18
    },
    {
        "id": "1.63",
        "group": "group B",
        "price": 320,
        "volume": 12
    },
    {
        "id": "1.64",
        "group": "group B",
        "price": 452,
        "volume": 16
    },
    {
        "id": "1.65",
        "group": "group B",
        "price": 391,
        "volume": 12
    },
    {
        "id": "1.66",
        "group": "group B",
        "price": 214,
        "volume": 8
    },
    {
        "id": "1.67",
        "group": "group B",
        "price": 329,
        "volume": 4
    },
    {
        "id": "1.68",
        "group": "group B",
        "price": 467,
        "volume": 13
    },
    {
        "id": "1.69",
        "group": "group B",
        "price": 408,
        "volume": 14
    },
    {
        "id": "1.70",
        "group": "group B",
        "price": 457,
        "volume": 13
    },
    {
        "id": "1.71",
        "group": "group B",
        "price": 212,
        "volume": 8
    },
    {
        "id": "1.72",
        "group": "group B",
        "price": 355,
        "volume": 18
    },
    {
        "id": "1.73",
        "group": "group B",
        "price": 271,
        "volume": 17
    },
    {
        "id": "1.74",
        "group": "group B",
        "price": 388,
        "volume": 18
    },
    {
        "id": "1.75",
        "group": "group B",
        "price": 324,
        "volume": 20
    },
    {
        "id": "1.76",
        "group": "group B",
        "price": 284,
        "volume": 15
    },
    {
        "id": "1.77",
        "group": "group B",
        "price": 184,
        "volume": 18
    },
    {
        "id": "2.0",
        "group": "group C",
        "price": 240,
        "volume": 6
    },
    {
        "id": "2.1",
        "group": "group C",
        "price": 349,
        "volume": 7
    },
    {
        "id": "2.2",
        "group": "group C",
        "price": 221,
        "volume": 10
    },
    {
        "id": "2.3",
        "group": "group C",
        "price": 234,
        "volume": 14
    },
    {
        "id": "2.4",
        "group": "group C",
        "price": 419,
        "volume": 17
    },
    {
        "id": "2.5",
        "group": "group C",
        "price": 215,
        "volume": 10
    },
    {
        "id": "2.6",
        "group": "group C",
        "price": 321,
        "volume": 16
    },
    {
        "id": "2.7",
        "group": "group C",
        "price": 452,
        "volume": 4
    },
    {
        "id": "2.8",
        "group": "group C",
        "price": 484,
        "volume": 18
    },
    {
        "id": "2.9",
        "group": "group C",
        "price": 340,
        "volume": 5
    },
    {
        "id": "2.10",
        "group": "group C",
        "price": 42,
        "volume": 14
    },
    {
        "id": "2.11",
        "group": "group C",
        "price": 100,
        "volume": 11
    },
    {
        "id": "2.12",
        "group": "group C",
        "price": 183,
        "volume": 4
    },
    {
        "id": "2.13",
        "group": "group C",
        "price": 17,
        "volume": 18
    },
    {
        "id": "2.14",
        "group": "group C",
        "price": 174,
        "volume": 15
    },
    {
        "id": "2.15",
        "group": "group C",
        "price": 358,
        "volume": 9
    },
    {
        "id": "2.16",
        "group": "group C",
        "price": 236,
        "volume": 5
    },
    {
        "id": "2.17",
        "group": "group C",
        "price": 398,
        "volume": 9
    },
    {
        "id": "2.18",
        "group": "group C",
        "price": 321,
        "volume": 9
    },
    {
        "id": "2.19",
        "group": "group C",
        "price": 236,
        "volume": 15
    },
    {
        "id": "2.20",
        "group": "group C",
        "price": 367,
        "volume": 14
    },
    {
        "id": "2.21",
        "group": "group C",
        "price": 169,
        "volume": 11
    },
    {
        "id": "2.22",
        "group": "group C",
        "price": 268,
        "volume": 20
    },
    {
        "id": "2.23",
        "group": "group C",
        "price": 464,
        "volume": 9
    },
    {
        "id": "2.24",
        "group": "group C",
        "price": 204,
        "volume": 15
    },
    {
        "id": "2.25",
        "group": "group C",
        "price": 7,
        "volume": 5
    },
    {
        "id": "2.26",
        "group": "group C",
        "price": 484,
        "volume": 14
    },
    {
        "id": "2.27",
        "group": "group C",
        "price": 287,
        "volume": 12
    },
    {
        "id": "2.28",
        "group": "group C",
        "price": 167,
        "volume": 9
    },
    {
        "id": "2.29",
        "group": "group C",
        "price": 86,
        "volume": 19
    },
    {
        "id": "2.30",
        "group": "group C",
        "price": 419,
        "volume": 20
    },
    {
        "id": "2.31",
        "group": "group C",
        "price": 397,
        "volume": 19
    },
    {
        "id": "2.32",
        "group": "group C",
        "price": 168,
        "volume": 4
    },
    {
        "id": "2.33",
        "group": "group C",
        "price": 461,
        "volume": 14
    },
    {
        "id": "2.34",
        "group": "group C",
        "price": 283,
        "volume": 20
    },
    {
        "id": "2.35",
        "group": "group C",
        "price": 426,
        "volume": 15
    },
    {
        "id": "2.36",
        "group": "group C",
        "price": 485,
        "volume": 14
    },
    {
        "id": "2.37",
        "group": "group C",
        "price": 496,
        "volume": 10
    },
    {
        "id": "2.38",
        "group": "group C",
        "price": 118,
        "volume": 20
    },
    {
        "id": "2.39",
        "group": "group C",
        "price": 499,
        "volume": 11
    },
    {
        "id": "2.40",
        "group": "group C",
        "price": 376,
        "volume": 20
    },
    {
        "id": "2.41",
        "group": "group C",
        "price": 284,
        "volume": 10
    },
    {
        "id": "2.42",
        "group": "group C",
        "price": 469,
        "volume": 4
    },
    {
        "id": "2.43",
        "group": "group C",
        "price": 45,
        "volume": 20
    },
    {
        "id": "2.44",
        "group": "group C",
        "price": 37,
        "volume": 11
    },
    {
        "id": "2.45",
        "group": "group C",
        "price": 159,
        "volume": 16
    },
    {
        "id": "2.46",
        "group": "group C",
        "price": 71,
        "volume": 20
    },
    {
        "id": "2.47",
        "group": "group C",
        "price": 242,
        "volume": 11
    },
    {
        "id": "2.48",
        "group": "group C",
        "price": 230,
        "volume": 14
    },
    {
        "id": "2.49",
        "group": "group C",
        "price": 444,
        "volume": 14
    },
    {
        "id": "2.50",
        "group": "group C",
        "price": 72,
        "volume": 15
    },
    {
        "id": "2.51",
        "group": "group C",
        "price": 308,
        "volume": 18
    },
    {
        "id": "2.52",
        "group": "group C",
        "price": 298,
        "volume": 7
    },
    {
        "id": "2.53",
        "group": "group C",
        "price": 473,
        "volume": 7
    },
    {
        "id": "2.54",
        "group": "group C",
        "price": 79,
        "volume": 20
    },
    {
        "id": "2.55",
        "group": "group C",
        "price": 366,
        "volume": 5
    },
    {
        "id": "2.56",
        "group": "group C",
        "price": 265,
        "volume": 4
    },
    {
        "id": "2.57",
        "group": "group C",
        "price": 274,
        "volume": 14
    },
    {
        "id": "2.58",
        "group": "group C",
        "price": 287,
        "volume": 17
    },
    {
        "id": "2.59",
        "group": "group C",
        "price": 463,
        "volume": 4
    }
]
