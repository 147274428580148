import React from "react";
import {ResponsiveStream} from '@nivo/stream'
import {nivoTheme} from "../../theme/nivoTheme";
import {Box, IconButton, Stack, Typography} from "@mui/material";
import {InfoOutlined, ZoomIn} from "@mui/icons-material";
import {PleshTheme} from "../../theme/pleshTheme";
import {filter} from "lodash";

export const StreamGraph = ({activity, handleSetOpen, handleZoom}) => {
    //console.log("TimelineGraph")
    //console.log("activity:",activity.length)
    /*console.log("filter: ",filter(activity, function (o) {
        return o._value > 0
    }))*/

    return (
        <>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}
                   sx={{width: '100%', textAlign: 'left'}}>
                <Typography>Attività durante le sessioni</Typography>
                <IconButton onClick={() => handleSetOpen('sessioni')}>
                    <InfoOutlined/>
                </IconButton>
                <IconButton onClick={handleZoom}>
                    <ZoomIn/>
                </IconButton>
            </Stack>
            <ResponsiveStream
                data={filter(activity, function (o) {
                    return o._value
                })}
                theme={nivoTheme}
                //colors={{scheme: 'paired'}}
                colors={nivoTheme.customPalettes.stream}
                keys={[
                    '_value'
                ]}
                margin={{top: 50, right: 20, bottom: 35, left: 48}}
                axisTop={null}
                axisRight={null}
                axisBottom={null/*{
                    tickSize: 10,
                    tickPadding: 5,
                    tickRotation: 90,
                    legend: 'Tempo',
                    legendOffset: 45,
                }*/}
                label={d => "Numero idee"}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    legend: 'Numero di idee',
                    tickRotation: -45,
                    legendOffset: -40,
                }}
                enableGridX={false}
                enableGridY={false}
                offsetType="none"
                fillOpacity={0.85}
                borderColor={{theme: 'background'}}
                dotColor={{from: 'color'}}
                dotBorderWidth={2}
                dotBorderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.7
                        ]
                    ]
                }}

            />
            <Typography textAlign={'right'} variant={'subtitle2'} fontSize={12}>Tempo</Typography>
        </>
    )
}