import React, {useMemo, useState} from "react";
import {ResponsivePie} from '@nivo/pie'
import _, {filter} from 'lodash'
import {nivoTheme} from "../../theme/nivoTheme";
import {IconButton, Stack, Typography} from "@mui/material";
import {InfoOutlined} from "@mui/icons-material";
import {PleshTheme} from "../../theme/pleshTheme";


const PieGraph = ({words, handleSetOpen}) => {
    //console.log("words:",words)
    const [open, setOpen] = useState(false)
    const maxWords = 10

    const parolePiuUsate = useMemo(() => {
        if (!words)
            return null
        return _.orderBy(filter(words, function (o) {
            return o.word !== 'should'
                && o.word !== 'with'
                && o.word !== 'have'
                && o.word !== 'that'
                && o.word !== 'there'
                && o.word !== 'also'
                && o.word !== 'from'
                && o.word !== 'could'
                && o.word !== 'would'
        }), ['count'], 'desc').slice(0, maxWords).map((el) => ({
            "id": el.word,
            "label": el.word,
            "value": el.count,
            //"color":
        }))
    }, [words])

    //console.log("parolePiuUsate:",parolePiuUsate)

    return (<>
        {parolePiuUsate ?
            <>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}
                       sx={{width: '100%', textAlign: 'left'}}>
                    <Typography>Le {maxWords} parole più utilizzate</Typography>
                    <IconButton onClick={() => handleSetOpen('parole')}>
                        <InfoOutlined/>
                    </IconButton>
                </Stack>
                <ResponsivePie
                    theme={nivoTheme}
                    colors={{scheme: 'paired'}}
                    colors={nivoTheme.customPalettes.pie}
                    data={parolePiuUsate}
                    margin={{top: 40, right: 80, bottom: 40, left: 80}}
                    innerRadius={0.4}
                    padAngle={3}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                0.2
                            ]
                        ]
                    }}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#eee"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsDiagonalLength={8}
                    arcLinkLabelsStraightLength={8}
                    arcLinkLabelsColor={{from: 'color'}}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                2
                            ]
                        ]
                    }}
                /></>
            : null
        }
    </>)
}

export default PieGraph
