import {createTheme, responsiveFontSizes} from "@mui/material";

export const PleshTheme = responsiveFontSizes(createTheme({
    typography: {fontFamily: "Grotesk"},
    palette: {
        mode: "dark",
        primary: {
            main: "#ab00f2"
        },
        secondary: {
            main: "#01ffff"
        },
        accent: {
            main: "#53ff0d"
        },
        background: {
            default: "#000000",
            paper: "#000000"
        }
    }

}))
