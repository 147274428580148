import React, {useMemo} from "react";
import {ResponsiveLine} from '@nivo/line'
import {nivoTheme} from "../../theme/nivoTheme";
import {IconButton, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {InfoOutlined, ZoomIn, ZoomOut} from "@mui/icons-material";
import {each, filter} from "lodash";
import {PleshTheme} from "../../theme/pleshTheme";

export const TimelineGraph = ({activity, handleSetOpen, handleZoom}) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

    const parsedData = useMemo(() => {
        if (!activity)
            return null

        let res
        const accumulator = {}

        each(filter(activity, function (o) {
            return o._value > 0
        }), (moment) => {
            if (moment && moment._time) {
                const date = new Date(moment._time)
                const dateKey = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}, ${date.getHours()}:${date.getMinutes()}`
                accumulator[dateKey] = moment._value
            }
        })

        res = Object.entries(accumulator).map(([key, counter], i) => {
            return {
                x: key,
                y: counter,
            }
        })
        return [{id: 'Numero di idee', data: res}]
    }, [activity])

    return (
        <>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}
                   sx={{width: '100%', textAlign: 'left'}}>
                <Typography>Attività durante le sessioni</Typography>
                <IconButton onClick={() => handleSetOpen('sessioni-timeline')}>
                    <InfoOutlined/>
                </IconButton>
                <IconButton onClick={handleZoom}>
                    <ZoomOut/>
                </IconButton>
            </Stack>
            <ResponsiveLine
                margin={{top: 50, right: 20, bottom: 35, left: 48}}
                animate={true}
                data={parsedData || []}
                theme={nivoTheme}
                //colors={{scheme: 'paired'}}
                colors={[PleshTheme.palette.primary.main]}
                enableArea={true}
                enableGridX={false}
                enableGridY={false}
                xScale={{
                    type: 'time',
                    format: '%Y-%m-%d, %H:%M',
                    useUTC: false,
                    precision: 'minute',
                }}
                xFormat={"time:%d/%m/%Y %H:%M"}
                yScale={{
                    type: 'linear',
                    stacked: false,
                }}
                yFormat=" >-.0f"
                axisLeft={{
                    legend: 'Numero di idee',
                    legendOffset: -40,
                    tickRotation: -45,
                }}
                axisBottom={{
                    format: '%H:%M',//'%b %d',
                    tickValues: 'every 30 minute',
                    tickRotation: -45,
                    //legend: 'Tempo',
                    legendOffset: 40,
                    legendPosition: 'end'
                }}
                curve={'monotoneX'}
                enablePointLabel={!mobile}
                pointSize={8}
                pointBorderWidth={1}
                pointBorderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.3]],
                }}
                useMesh={true}
                enableSlices={false}
            />
            <Typography textAlign={'right'} fontSize={12}>Tempo</Typography>
        </>
    )
}


const CustomSymbol = ({size, color, borderWidth, borderColor}) => (
    <g>
        <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor}/>
        <circle
            r={size / 5}
            strokeWidth={borderWidth}
            stroke={borderColor}
            fill={color}
            fillOpacity={0.35}
        />
    </g>
)