import React, {useMemo} from "react";
import {Chip, IconButton, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {InfoOutlined} from "@mui/icons-material";
import {ResponsiveChord} from "@nivo/chord";
import {nivoTheme} from "../../theme/nivoTheme";
import {PleshTheme} from "../../theme/pleshTheme";
import {BasicTooltip, TableTooltip} from "@nivo/tooltip";

function ChordGraph2({connectionsByWords, handleSetOpen, empty = false, handleChord}) {
    const [tavoli, data] = useMemo(() => {
        if (!connectionsByWords) return [null, null];

        const _tavoli = Object.keys(connectionsByWords);

        const graphData = Array.from({ length: _tavoli.length }, () =>
            new Array(_tavoli.length).fill(1)
        );

        if (empty) return [_tavoli, graphData];

        for (let i = 0; i < _tavoli.length; i++) {
            const tavolo = _tavoli[i];
            const index1 = i;
            const connections = connectionsByWords[tavolo];

            for (let [conn, val] of Object.entries(connections)) {
                const index2 = _tavoli.indexOf(conn);
                graphData[index1][index2] = val;
            }
        }

        return [_tavoli, graphData];
    }, [connectionsByWords, empty]);

    return (<>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}
                   sx={{width: '100%', textAlign: 'left'}}>
                <Typography>{
                    empty ?
                        "Se nessuno avesse condiviso le proprio idee..."
                        : "Tutte le connessioni di idee che avete creato fra i tavoli"
                }</Typography>
                <IconButton onClick={() => handleSetOpen(!empty ? 'chord' : 'emptyChord')}>
                    <InfoOutlined/>
                </IconButton>
            </Stack>
            <ResponsiveChord
                data={data||[]}
                keys={tavoli||[]}
                theme={nivoTheme}
                colors={{ scheme: 'nivo' }}
                colors={nivoTheme.customPalettes.chord}
                margin={{ top: 40, right: 40, bottom: 90, left: 40 }}
                valueFormat=".0f"
                padAngle={0.02}
                innerRadiusRatio={0.96}
                innerRadiusOffset={0.02}
                inactiveArcOpacity={0.25}
                arcBorderColor={ empty ? null : {
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.6
                        ]
                    ]
                }}
                activeRibbonOpacity={empty ? 0 : 0.75}
                inactiveRibbonOpacity={empty ? 0 : 0.25}
                ribbonOpacity={empty ? 0 : 0.5}
                ribbonBorderColor={empty ? null : {
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.6
                        ]
                    ]
                }}
                labelRotation={-90}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1
                        ]
                    ]
                }}
                motionConfig="stiff"
                animate={!empty}
                isInteractive={!empty}
                arcTooltip={ArcTooltip}
                ribbonTooltip={RibbonTooltip}
                /*legends={[
                    {
                        anchor: 'left',
                        direction: 'column',
                        justify: false,
                        translateX: 0,
                        translateY: 70,
                        itemWidth: 80,
                        itemHeight: 14,
                        itemsSpacing: 20,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        symbolSize: 12,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}*/
            />
        </>
    );
}

export default ChordGraph2

const ArcTooltip = ({ arc }) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

    return (
        <Stack direction={mobile ? 'column' : 'row'} spacing={1} sx={{background: 'black'}} p={1} borderRadius={'4px'}
               alignItems={mobile ? 'left' : 'center'} justifyContent={'center'} maxWidth={mobile ? '140px' : 'inherit'} flexWrap={'wrap'}>
            <Chip size={'small'} sx={{background: arc?.color}} label={`Tavolo ${arc?.label}:`}/>
            <Typography textAlign={'left'} fontSize={12}>{arc?.formattedValue} idee in comune con altri tavoli</Typography>
        </Stack>
        /*<BasicTooltip
          id={`Tavolo ${arc.label}`}
          value={`${arc.formattedValue} idee in comune con altri tavoli`}
          color={arc.color}
          enableChip={true}
      />*/
    )
}

const RibbonTooltip = ({ ribbon }) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

    return (<Stack alignItems={'center'} sx={{background: 'black'}} p={1} borderRadius={'4px'} spacing={1}
                   maxWidth={mobile ? '140px' : 'inherit'}>
            <Stack direction={mobile ? 'column' : 'row'} spacing={1} flexWrap={'wrap'} justifyContent={'left'}>
                <Chip size={'small'} sx={{background: ribbon?.source?.color}} label={`Tavolo ${ribbon?.source?.id}`}/>
                <Chip size={'small'} sx={{backgroundColor: ribbon?.target?.color}}
                      label={`Tavolo ${ribbon?.target?.id}`}/>
            </Stack>
            <Stack direction={'row'}>
                <Typography textAlign={'left'} variant={'subtitle2'} fontSize={'12'}>{ribbon?.source?.value} idee in comune</Typography>
            </Stack>
        </Stack>
        /*<TableTooltip
          rows={[
                    [
                        <Chip key="chip" sx={{background: ribbon?.source?.color}} />,
                  'Source (custom)',
                  <strong key="id">{ribbon?.source?.id}</strong>,
                  ribbon?.source?.value,
                ],
              [
                  <Chip key="chip" sx={{background: ribbon?.target?.color}} />,
                  'Target (custom)',
                  <strong key="id">{ribbon?.target?.id}</strong>,
                  ribbon?.target?.value,
                ],
          ]}
      />*/
    )
}