import {PleshTheme} from "./pleshTheme";

export const nivoTheme = {
    "background": "transparent",
    "textColor": "#fff",
    "fontSize": 11,
    "axis": {
        "domain": {
            "line": {
                "stroke": "#fff",
                "strokeWidth": 1
            }
        },
        "legend": {
            "text": {
                "fontSize": 12,
                "fill": "#fff",
                "fontFamily": "Grotesk",
            }
        },
        "ticks": {
            "line": {
                "stroke": "#fff",
                "strokeWidth": 1
            },
            "text": {
                "fontSize": 12,
                "fill": "#fff"
            }
        }
    },
    "grid": {
        "line": {
            "stroke": "#dddddd",
            "strokeWidth": 1
        }
    },
    "legends": {
        "title": {
            "text": {
                "fontSize": 11,
                "fill": "#fff"
            }
        },
        "text": {
            "fontSize": 11,
            "fill": "#fff"
        },
        "ticks": {
            "line": {},
            "text": {
                "fontSize": 10,
                "fill": "#fff"
            }
        }
    },
    "annotations": {
        "text": {
            "fontSize": 13,
            "fill": "#333333",
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        },
        "link": {
            "stroke": "#fff",
            "strokeWidth": 1,
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        },
        "outline": {
            "stroke": "#fff",
            "strokeWidth": 2,
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        },
        "symbol": {
            "fill": "#fff",
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        }
    },
    "tooltip": {
        "container": {
            "background": "#000",
            "color": "#fff",
            "fontSize": 12
        },
        "basic": {},
        "chip": {},
        "table": {},
        "tableCell": {},
        "tableCellValue": {}
    },
    customPalettes: {
        pie: [
            PleshTheme.palette.primary.main,
            PleshTheme.palette.secondary.main,
            PleshTheme.palette.accent.main,
            '#5200f1',
            '#d03528',
            '#abff09',
            PleshTheme.palette.primary.main,
            PleshTheme.palette.secondary.main,
            PleshTheme.palette.accent.main,
            '#5200f1',
            '#d03528',
            '#abff09',
        ],
        stream: [PleshTheme.palette.primary.main],
        chord: [
            PleshTheme.palette.primary.main,
            PleshTheme.palette.secondary.main,
            PleshTheme.palette.accent.main,
            '#5200f1',
            '#d03528',
            '#abff09',
            PleshTheme.palette.primary.main,
            PleshTheme.palette.secondary.main,
            PleshTheme.palette.accent.main,
            '#5200f1',
            '#d03528',
            '#abff09',
        ],
        swarmPlot: [
            PleshTheme.palette.primary.main,
            PleshTheme.palette.secondary.main,
            PleshTheme.palette.accent.main,
        ]
    }
}
