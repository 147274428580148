import React from "react";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import {Default} from "../layouts/Default";
import {useParams} from "react-router-dom";
import {Box, CircularProgress, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Textfit} from 'react-textfit';
import useAxios from "axios-hooks";
import {Description} from "../components/Description";
import Counters from "../components/Counters";
import {Graphs} from "../components/Graphs";
import {API_URL} from "../config";
import {PleshTheme} from "../theme/pleshTheme";

export const Event = () => {
    const {id} = useParams()
    const [event, loading, error] = useDocumentData(firestore.doc(`events/${id}`))
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

    const [{
        data: eventData,
        loading: eventDataLoading,
        error: eventDataError
    }, refetchEventData] = useAxios(`${API_URL}/events/${id}/`)

    const [{
        data: sessions,
        loading: dataLoading,
        error: dataError
    }, refetch] = useAxios(`${API_URL}/events/${id}/sentences`)
    const [{
        data: words,
        loading: wordsLoading,
        error: wordsError
    }, refetchWords] = useAxios(`${API_URL}/events/${id}/words`)
    const [{
        data: activity,
        loading: activityLoading,
        error: activityError
    }, refetchActivity] = useAxios(`${API_URL}/events/${id}/activity`)
    const [{
        data: connectionsByWords,
        loading: connectionsByWordsLoading,
        error: connectionsByWordsError
    }, refetchConnectionsByWords] = useAxios(`${API_URL}/events/${id}/connectionsByWord`)

    return (
        <Default>
            <Stack alignItems={'center'}>
                {loading && <CircularProgress/>}
                {event &&
                    <Box>
                        <Box sx={{py: 4}}>
                            {event.placeDateTime &&
                                <Typography gutterBottom textAlign={'right'} color={PleshTheme.palette.primary.main}>
                                    {event.placeDateTime}
                                </Typography>}
                            <Textfit mode="multi"
                                     style={{
                                         height: mobile ? "150px" : "min(50vw, 300px)",
                                         textTransform: 'uppercase',
                                         fontFamily: "'Grotesk', sans-serif",
                                         fontWeight: '700',
                                         lineHeight: '1',
                                     }} min={1} max={200}>
                                {event.name}
                            </Textfit>
                        </Box>
                        {event.descriptions && <Description descriptions={event.descriptions}/>}
                        <Stack alignItems={'center'}>
                            {(eventDataLoading || dataLoading || wordsLoading || activityLoading || connectionsByWordsLoading) ?
                                <CircularProgress/>
                                :
                                <Stack width={'100%'}>
                                    <Counters event={{...event, numIdee: eventData?.num_ideas}} sessions={sessions}/>
                                    {<Graphs event={event} sessionsLabels={eventData?.sessions} sessions={sessions} words={words} activity={activity} connectionsByWords={connectionsByWords}/>}
                                </Stack>
                            }
                        </Stack>
                    </Box>}
            </Stack>
        </Default>
    )
}
