import React, {useContext, useMemo, useState} from "react";
import {ResponsiveSwarmPlot} from '@nivo/swarmplot'
import _, {find, isEmpty, orderBy} from 'lodash'
import {Autocomplete, Box, Chip, IconButton, Paper, Stack, TextField, Typography} from "@mui/material";
import {InfoOutlined, Square} from "@mui/icons-material";
import {CustomTooltipSwarmPlot} from "./SwarmPlotGraph";
import {PleshTheme} from "../../theme/pleshTheme";
import {nivoTheme} from "../../theme/nivoTheme";

const SwarmPlotGraphOld = ({sessions, sessionsLabels, connectionsByWords, handleSetOpen}) => {
    const [selectedSession, setSelectedSession] = useState(find(sessionsLabels, ['position', 0])
        || find(sessionsLabels, ['id', '1']))

    const _tavoli = useMemo(() => {
        if(!connectionsByWords)
            return null

        return Object.keys(connectionsByWords);
    }, [connectionsByWords])

    // x: numero parole, y: tavoli, grandezza: sentimento
    /**
     * {
     *     "id": "0.0",
     *     "group": "group A",
     *     "price": 1,
     *     "volume": 9
     *   }
     * */

    const [__data, sessionsKeys] = useMemo(() => {
        if(!sessions)
            return [[], []]

        return [_.map(sessions[selectedSession?.id], (frase, index) => ({
            id: `${index} - ${frase.keyword}`,
            group: frase.table,
            wordCounter: _.split(frase.sentence, ' ').length,
            sentiment: frase.sentiment.score,
            frase: frase.sentence,
            citta: frase.zone,
            keyWord: frase.keyword
        })), Object.keys(sessions)]
    }, [sessions, selectedSession])

    //console.log("sessionsKeys:",sessionsKeys)
    //console.log("sessionsLabels:",sessionsLabels)
    return (<>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}
               sx={{width: '100%', textAlign: 'left'}}>
            <Typography>I Sentiment delle vostre idee</Typography>
            <IconButton onClick={() => handleSetOpen('swamPlot')}>
                <InfoOutlined/>
            </IconButton>
            <Autocomplete
                sx={{width: '50%', marginLeft: 'auto'}}
                value={selectedSession}
                getOptionLabel={(option) => `Sessione #${option?.name || option.id}`}
                renderOption={(props, option) => (
                    <Box component="li" {...props} display={'flex'} justifyContent={'space-between'}>
                        <Chip label={`Sessione #${option?.name || option.id}`} size={'small'} sx={{marginLeft: 1}}/>
                    </Box>
                )}
                onChange={(event, newValue) => {
                    if(newValue)
                        setSelectedSession(newValue)
                }}
                renderInput={(params) => <TextField {...params} label="Sessione" />}
                options={orderBy(sessionsLabels, ['position', 'asc'])}
            />
        </Stack>
        {_tavoli && !isEmpty(_tavoli) && <ResponsiveSwarmPlot
            data={__data}
            groups={_tavoli || ['1']}
            identity="id"
            value="wordCounter"
            colors={nivoTheme.customPalettes.swarmPlot}
            //tooltip={props => console.log("props:",props)}
            tooltip={(props) => <CustomTooltipSwarmPlot {...props}/>}
            //valueFormat=" >-$.2f"
            valueScale={{ type: 'symlog', min: 0, max: _.maxBy(__data, 'wordCounter')?.wordCounter, reverse: false }}
            layout={"horizontal"}
            size={{
                key: 'sentiment',
                values: [
                    0,
                    2
                ],
                sizes: [
                    4,
                    25
                ]
            }}
            forceStrength={0.4}
            simulationIterations={60}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.6
                    ],
                    [
                        'opacity',
                        0.5
                    ]
                ]
            }}
            margin={{ top: 110, right: 25, bottom: 80, left: 55 }}
            axisTop={false/*{
                orient: 'top',
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -46
            }*/}
            axisRight={false/*{
                orient: 'right',
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 76
            }*/}
            axisBottom={{
                orient: 'bottom',
                tickSize: 15,
                tickPadding: 8,
                tickRotation: 90,
                legend: 'Lunghezza delle frasi',
                legendPosition: 'end',
                legendOffset: 55
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 15,
                tickPadding: 8,
                tickRotation: 0,
                legend: 'TAVOLI',
                legendPosition: 'end',
                legendOffset: -50
            }}
        />}
    </>)
}

export default SwarmPlotGraphOld

const CustomTooltip = (props) => {
    const {sentiment, frase, keyWord} = props.data
    const {color, group} = props
    return (<Box p={0.5} component={Paper} display={'flex'} maxWidth={'400px'}>
        <Square sx={{color:color, mr:0.5}} fontSize={'small'}/>
        <Typography variant={'caption'} align={'left'}><strong>Tavolo {group}</strong>: {frase}</Typography>
    </Box>)
}

