import React, {useMemo} from "react";
import {ResponsiveRadar} from '@nivo/radar'
import _, {filter, map} from 'lodash'
import {trimAndLower} from "../../functions/trimAndLower";
import {nivoTheme} from "../../theme/nivoTheme";
import {IconButton, Stack, Typography} from "@mui/material";
import {InfoOutlined} from "@mui/icons-material";
import {PleshTheme} from "../../theme/pleshTheme";

function normalize(sentimento, max, min) {
    return sentimento * ((max - min) / 5)
}

const RadarGraph = ({words, sessions, handleSetOpen}) => {
    const maxWords = 10

    const parolePiuUsate = useMemo(() => {
        if (!words)
            return null
        return map(_.orderBy(words, ['count'], 'desc').slice(0, maxWords),
            (word) => (word.word)
        )
    }, [words])

    const [paroleChiavePiuUsate, paroleChiavePiuAmate, paroleChiaveMenoAmate] = useMemo(() => {
        if (!sessions && !parolePiuUsate)
            return [null, null, null]
        const res = {}
        _.forEach(sessions, (session) => {
            _.forEach(filter(session, function (o) {
                return parolePiuUsate?.includes(o.keyword) //&& o.sentiment.numHits > 2 // TODO CAPIRE SE SCOMMENTARRRR
            }), (sentence) => {
                let key = trimAndLower(sentence.keyword)
                if (!res[key])
                    res[key] = {
                        key: key,
                        counter: 1,
                        //sentimento_originale: sentence.original_sentiment * 0.2,
                        sentiment: sentence.sentiment.score
                    }
                else {
                    res[key] = {
                        ...res[key],
                        counter: ++res[key].counter,
                        sentiment: (res[key].sentiment + sentence.sentiment.score) / 2
                    }
                }
            })
        })

        return [
            _.orderBy(res, 'counter', 'desc').slice(0, maxWords),
            _.orderBy(res, 'sentiment', 'desc').slice(0, maxWords),
            _.orderBy(res, 'sentiment').slice(0, maxWords),
        ]
    }, [sessions, parolePiuUsate])

    const [data1] = useMemo(() => {
        if (!paroleChiavePiuUsate || !paroleChiavePiuAmate || !paroleChiaveMenoAmate)
            return [null, null, null]

        return [
            paroleChiavePiuUsate.map((parola) => ({
                parola: parola.key,
                sentiment: parola.sentiment,
                //sentiment_originale: parola.sentiment_originale
            })),
            /*paroleChiavePiuAmate.map((parola) => ({
                parola: parola.key,
                counter: parola.counter,
                sentiment: parola.sentiment
            })),
            paroleChiaveMenoAmate.map((parola) => ({
                parola: parola.key,
                counter: parola.counter,
                sentiment: parola.sentiment
            }))*/
        ]
    }, [paroleChiavePiuUsate])

    function getSentimentLabel(value) {
        if (value >= 0 && value <= 0.75)
            return "Negativo"
        else if (value > 0.75 && value <= 1.25)
            return "Neutro"
        else if (value > 1.25)
            return "Positivo"
    }

    return (<>
            {data1 ? <>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}
                       sx={{width: '100%', textAlign: 'left'}}>
                    <Typography>Radar Sentiment</Typography>
                    <IconButton onClick={() => handleSetOpen('radar')}>
                        <InfoOutlined/>
                    </IconButton>
                </Stack>
                <ResponsiveRadar
                    data={data1}
                    keys={['sentiment']}
                    indexBy="parola"
                    //enableDotLabel
                    theme={nivoTheme}
                    //colors={{scheme: 'nivo'}}
                    //colors={{scheme: 'paired'}}
                    colors={[PleshTheme.palette.primary.main]}
                    //valueFormat=">-.2f"
                    valueFormat={value =>
                        `${value?.toPrecision(2)}: ${getSentimentLabel(value)}`
                    }
                    //gridShape="linear"
                    //curve="cardinalClosed"
                    //maxValue={2}
                    margin={{top: 40, right: 80, bottom: 40, left: 80}}
                    borderColor={{from: 'color'}}
                    dotSize={10}
                    dotColor={{theme: 'background'}}
                    dotBorderWidth={2}
                    blendMode="normal"
                    motionConfig="wobbly"
                />
            </> : null}
        </>
    )
}

export default RadarGraph


const data = [
    {
        "taste": "fruity",
        "chardonay": 80,
        "carmenere": 77,
        "syrah": 30
    },
    {
        "taste": "bitter",
        "chardonay": 88,
        "carmenere": 96,
        "syrah": 88
    },
    {
        "taste": "heavy",
        "chardonay": 40,
        "carmenere": 110,
        "syrah": 105
    },
    {
        "taste": "strong",
        "chardonay": 63,
        "carmenere": 112,
        "syrah": 55
    },
    {
        "taste": "sunny",
        "chardonay": 107,
        "carmenere": 46,
        "syrah": 99
    }
]
